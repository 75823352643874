String.prototype.ucfirst = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.ucwords = function () {
  return this.toLowerCase().replace(/\b[a-z]/g, function (letter) {
    return letter.toUpperCase();
  });
};

String.prototype.titleize = function () {
  return this.replace(/[-_]/g, " ")
    .split(" ")
    .map((str) => str.ucfirst())
    .join(" ");
};

String.prototype.camelCase = function () {
  return this.replace(/[-_]/g, " ")
    .split(" ")
    .map((str, key) => (key == 0 ? str.toLowerCase() : str.ucfirst()))
    .join("");
};
