function Ajax() {
  let defaultHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
  };

  function buildEnpoint(path) {
    const BASE_URL =
      process.env.REACT_APP_API_URL || "https://app.rannkly.com/uqc";
    return `${BASE_URL}/${path}`;
  }

  async function parseBody(response) {
    const contentType = response.headers.get("content-type") || "";
    if (contentType.includes("application/json")) return response.json();
    if (contentType.includes("text/html")) return response.text();
    return response.text();
  }

  this.get = async function (url, headers = {}) {
    const response = await fetch(buildEnpoint(url), {
      headers: { ...defaultHeaders, ...headers },
      method: "GET",
    });

    return parseBody(response);
  };

  this.post = async function (url, data = {}, headers = {}) {
    const response = await fetch(buildEnpoint(url), {
      headers: { ...defaultHeaders, ...headers },
      method: "POST",
      body: JSON.stringify(data),
    });

    return parseBody(response);
  } 
}

window.ajax = new Ajax();
