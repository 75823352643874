import React, { lazy } from "react";
import { Routes as Switch, Route } from "react-router-dom";

const Onboard = lazy(() => import("../views/Home/Index"));
const Main = lazy(() => import("../views/Home/Main"));

function Routes() {
  return (
    <>
      <Switch>
        <Route exact path="/" element={<Onboard />} />
        <Route path="*" element={<Main />} />
      </Switch>
    </>
  );
}

export default Routes;
