import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes/Index';
import Loading from './components/loading';

import './App.scss';

function App() {
  return (
    <Suspense fallback={<Loading size="center-loader" />}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
